import config from 'config/app'

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  GET_CONFIG_ERROR,
  GET_CONFIG_SUCCESS,
  IS_GETTING_CONFIG,

  GET_RANK_ERROR,
  GET_RANK_SUCCESS,
  IS_GETTING_RANK,

  JOIN_ERROR,
  JOIN_SUCCESS,
  IS_JOINING,

  GET_STAT_ERROR,
  GET_STAT_SUCCESS,
  IS_GETTING_STAT,

  GET_DETAIL_ERROR,
  GET_DETAIL_SUCCESS,
  IS_GETTING_DETAIL,

  EXCHANGE_ERROR,
  EXCHANGE_SUCCESS,
  IS_EXCHANGING,

  SHOW_MODAL_GUIDE,
  CLOSE_MODAL_GUIDE,

  SHOW_MODAL_PRIZE,
  CLOSE_MODAL_PRIZE
} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: {},
  isGettingConfig: true,
  provinces: [],
  levelRewards: [],
  weeklyRewards: [],
  userSeasonInfo: {},
  seasonInfo: {},
  seasonRewards: [],
  isGettingRank: false,
  isJoining: false,
  ranks: [],
  userRank: {},
  userStat: {
    weeks: [],
    season: []
  },
  userDetail: {},
  isGettingStat: true,
  isGettingDetail: true,
  isExchanging: false,
  modalGuideStatus: false,
  modalPrizeStatus: false,
  currentTime: null
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        userSeasonInfo: payload.user.season_info,
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        errorGlobal: 'Không có thông tin user'
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

    //GET CONFIG
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        isGettingConfig: false,
        provinces: payload.all_province,
        levelRewards: payload.all_level_reward,
        weeklyRewards: payload.all_weekly_reward,
        seasonInfo: payload.season_config,
        seasonRewards: payload.all_season_reward,
        currentTime: payload.current_time
      }
      break;
    case GET_CONFIG_ERROR:
      return {
        ...state,
        isGettingConfig: false,
      }
      break;
    case IS_GETTING_CONFIG:
      return {
        ...state,
        isGettingConfig: true
      }
      break;

    //RANK
    case GET_RANK_SUCCESS:
      return {
        ...state,
        isGettingRank: false,
        ranks: payload.ranking,
        userRank: payload.my_season_week
      }
      break;
    case GET_RANK_ERROR:
      return {
        ...state,
        isGettingRank: false
      }
      break;
    case IS_GETTING_RANK:
      return {
        ...state,
        isGettingRank: true
      }
      break;

    //JOIN
    case JOIN_SUCCESS:
      return {
        ...state,
        isJoining: false,
        userSeasonInfo: payload,
        userRank: {
          ...state.userRank,
          ...payload.current_week
        }
      }
      break;
    case JOIN_ERROR:
      return {
        ...state,
        isJoining: false
      }
      break;
    case IS_JOINING:
      return {
        ...state,
        isJoining: true
      }
      break;

    //STAT
    case GET_STAT_SUCCESS:
      return {
        ...state,
        isGettingStat: false,
        userStat: {
          ...state.userStat,
          weeks: payload.weeks,
          season: payload.season
        }
      }
      break;
    case GET_STAT_ERROR:
      return {
        ...state,
        isGettingStat: false
      }
      break;
    case IS_GETTING_STAT:
      return {
        ...state,
        isGettingStat: true
      }
      break;

    //DETAIL
    case GET_DETAIL_SUCCESS:
      return {
        ...state,
        isGettingDetail: false,
        userDetail: payload.current_season_detail
      }
      break;
    case GET_DETAIL_ERROR:
      return {
        ...state,
        isGettingDetail: false
      }
      break;
    case IS_GETTING_DETAIL:
      return {
        ...state,
        isGettingDetail: true
      }
      break;

    //EXCHANGE
    case EXCHANGE_SUCCESS:
      return {
        ...state,
        isExchanging: false,
        userDetail: {
          ...state.userDetail,
          exchanged: [
            ...state.userDetail.exchanged,
            payload.exchanged.id
          ]
        }
      }
      break;
    case EXCHANGE_ERROR:
      return {
        ...state,
        isExchanging: false
      }
      break;
    case IS_EXCHANGING:
      return {
        ...state,
        isExchanging: payload
      }
      break;
    
    //MODAL
    case SHOW_MODAL_GUIDE:
      return {
        ...state,
        modalGuideStatus: true
      }
      break;
    case CLOSE_MODAL_GUIDE:
      return {
        ...state,
        modalGuideStatus: false
      }
      break;
    case SHOW_MODAL_PRIZE:
      return {
        ...state,
        modalPrizeStatus: true
      }
      break;
    case CLOSE_MODAL_PRIZE:
      return {
        ...state,
        modalPrizeStatus: false
      }
      break;

    default:
      return state
  }
}
