import React from 'react'
import { NavLink } from 'react-router-dom'

import lib from 'lib/commons'
import config from 'config/app'

const Header = ({ currentUser: { login, user } }) => {
  return (
    <header id="header">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <NavLink to="/" className="logo"><img src="/images/logo.png" alt=""/></NavLink>
          </div>
          <div className="col-6 text-right" data-html2canvas-ignore>
            {!login ? (
              <a href="#" className="login-url" onClick={event => {
                event.preventDefault()
                lib.showLogin(event)
              }}>Đăng nhập</a>
            ) : (
              <a href="/user/logout" className="login-url"><span>{user.account_name}</span> Đăng xuất</a>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header