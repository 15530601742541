export default {
  zones: [
    {
      id: 'bac',
      name: 'Miền Bắc',
      image: '/images/logo-bac.png',
      imageActive: '/images/logo-bac-active.png',
      imageBig: '/images/logo-bac-big.png',
      bg: '/images/zone-bac.jpg'
    },
    {
      id: 'trung',
      name: 'Miền Trung',
      image: '/images/logo-trung.png',
      imageActive: '/images/logo-trung-active.png',
      imageBig: '/images/logo-trung-big.png',
      bg: '/images/zone-trung.jpg'
    },
    {
      id: 'nam',
      name: 'Miền Nam',
      image: '/images/logo-nam.png',
      imageActive: '/images/logo-nam-active.png',
      imageBig: '/images/logo-nam-big.png',
      bg: '/images/zone-nam.jpg'
    }
  ],
  milestone: [1, 100, 1000, 2000, 3000],
  prizes: [1, 4, 5, 6, 7, 8],
  rankType: {
    no_1: '1',
    no_2: '2',
    no_3: '3',
    no_4: '4',
    no_5: '5',
    top_1_percent: '1',
    top_5_percent: '5',
    top_10_percent: '10',
    top_25_percent: '25',
    top_50_percent: '50',
  }
}
