import config from "config/app.js";
import autoRedux from './autoRedux.js';

/**
* Socket IO client. The value should be set in redux/realtime.js
*/
window.raffleSocket = null;

/**
* Example: Commons.getSocket().emit('sample-event', {message: 'Hello Vietnam'})
*/
let getSocket = () => {
  return window.raffleSocket;
}

let isInteger = (number) => {
  let numberInt = parseInt(number)
  return numberInt == number
}

let isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const range = (start, end) => Array.from({length: (end - start + 1)}, (v, k) => k + start);

const toObj = (array, key) => {
  var result = array.reduce(function(map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}

const openModal = () => {
  $('body').addClass('modal-open');
  $('.modal-backdrop').show().addClass('show');
  $('#modal-rewards').show().addClass('show');
  return false;
}

const closeModal = (event) => {
  event.preventDefault();
  $('body').removeClass('modal-open');
  $('body').css('paddingRight', '0');
  $('.modal-backdrop').hide().removeClass('show');
  $('#modal-rewards').hide().removeClass('show');
  return false;
}

const secondsToTime = (secs) => {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
    };
    return obj;
}

const pagination = (items, page, per_page) => {
 
  var page = page || 1,
  per_page = per_page || 10,
  offset = (page - 1) * per_page,
 
  paginatedItems = items.slice(offset).slice(0, per_page),
  total_pages = Math.ceil(items.length / per_page);
  return {
    page: page,
    per_page: per_page,
    pre_page: page - 1 ? page - 1 : null,
    next_page: (total_pages > page) ? page + 1 : null,
    total: items.length,
    total_pages: total_pages,
    data: paginatedItems
  };
}
const paginationAll = (items, per_page = 6) => {
  let total_pages = Math.ceil(items.length / per_page);
  let pagingItems = []
  if(total_pages >= 1) {
    for (let index = 1; index <= total_pages; index++) {
      let offset = (index - 1) * per_page
      let paginatedItems = items.slice(offset).slice(0, per_page)
      pagingItems.push(paginatedItems)
    }
  }

  return pagingItems
}
const formatNumber = (num, demical = '.') => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + demical)
}
const showLogin = (event) => {
  event.preventDefault()

  Swal.fire({
    title: '<span>Đăng nhập</span>',
    'html': '<p><a href="/user/login?platform=3"><img src="/images/logo-fb.png" alt=""/></a> &nbsp; <a href="/user/login?platform=5"><img src="/images/logo-vk.png" alt=""/></a> &nbsp; <a href="/user/login?platform=8"><img src="/images/logo-gm.png" alt=""/></a></p>',
    showConfirmButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    customClass: {
      popup: 'popup-small'
    },
  }).then(res => res.value && (
    window.location = '/user/login/1'
  ))
}
const showError = (code) => {
  switch (code) {
    case 'missing_account_id':
      return 'Account không hợp lệ'
      break;
    case 'invalid_region':
      return 'Your location is not supported'
      break;
    case 'invalid_stage':
      return 'Vật phẩm chưa thể nhận hoặc bạn cần nhận vật phẩm ở cấp dưới trước'
      break;
    case 'permission_denied':
      return 'Thao tác không hợp lệ'
      break;
    case 'guest_disallowed':
      return 'Tài khoản guest không thể tham gia sự kiện'
      break;
    case 'card_does_not_exist':
      return 'Thẻ không tồn tại'
      break;
    case 'user_processing':
      return 'Hệ thống đang xử lý'
      break;
    case 'time_invalid':
      return 'Thời gian không hợp lệ'
      break;
    case 'invalid_team_id':
      return 'Không tìm thấy Hội'
      break;
    case 'invalid_user':
      return 'Không tồn tại user'
      break;
    case 'team_not_found':
      return 'Không tìm thấy Hội'
      break;
    case 'team_creation_time_limit_exceeded':
      return 'Bạn đã đạt giới hạn tạo Hội'
      break;
    case 'already_joined_other_team':
      return 'Bạn đã tham gia Hội khác'
      break;
    case 'pre_event_already_shared':
      return 'Bạn đã chia sẻ rồi'
      break;
    case 'user_application_limit_exceeded':
      return 'Đã vượt quá số lượng xin phép'
      break;
    case 'team_application_limit_exceeded':
      return 'Đã vượt quá số lượng xin phép'
      break;
    case 'team_member_limit_exceeded':
      return 'Số lượng thành viên đã đủ'
      break;
    case 'no_application':
      return 'Không có đơn đăng ký'
      break;
    case 'already_joined_other_team_error_application':
      return 'Thành viên đã tham gia hội khác'
      break;
    case 'wheel_already_shared':
      return 'Bạn đã share rồi'
      break;
    case 'user_update_info_already':
      return 'Bạn đã cập nhật thông tin rồi'
      break;
    case 'user_update_info_unnecessary':
      return 'Bạn không cần cập nhật thông tin'
      break;
    case 'send_notify_already':
      return 'Đã kêu gọi anh em hôm nay rồi. Ngày mai lại tiếp nhé Đại Ca.'
      break;
    case 'spin_limit_exceeded':
      return 'Bạn đã đạt số lượng quay tối đa'
      break;
    case 'server_reward_already_claimed':
      return 'Bạn đã nhận vật phẩm rồi'
      break;
    default:
      return 'Hiện không thể thực hiện, vui lòng thử lại sau'
      break;
  }
}
const convertDateForIos = (date) => {
  var arr = date.split(/[- :]/);
  date = new Date(parseInt(arr[0]), parseInt(arr[1]-1), parseInt(arr[2]), parseInt(arr[3]), parseInt(arr[4]), parseInt(arr[5]));
  return date;
}
export default {
  post: autoRedux.post,
  get: autoRedux.get,
  autoPost: autoRedux.autoPost,
  autoGet: autoRedux.autoGet,
  getSocket,
  isInteger,
  isJsonString,
  range,
  toObj,
  openModal,
  closeModal,
  secondsToTime,
  pagination,
  paginationAll,
  showError,
  showLogin,
  formatNumber,
  convertDateForIos
}
