import React, { useState } from 'react'
import Modal from 'react-modal'
import Scrollbar from 'react-scrollbars-custom'

Modal.setAppElement('#root');

const ModalPrize = ({ modalStatus, closeModal, showModalGuide }) => {

  const afterOpenModal = () => {
  }

  const selfClose = (event) => {
    event.preventDefault()
    closeModal()
  }

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="swal2-show modal-select"
    >
      <div className="modal-description">
        <h2>Phần thưởng</h2>
        <div className="guide-content">
        <Scrollbar 
          style={ {height: 200} }
          noScrollX={true}>
          <div>
            <p className="c14 c19"><span className="c2">Tham gia ĐUA TOP CHIẾN ĐỊA, người chơi có cơ hội nhận được:</span></p>
            <ul className="c8 lst-kix_ujabmlxm7sdy-0 start">
              <li className="c14 c19 c18 c21"><span className="c2">QUÀ XẾP HẠNG MÙA</span></li>
              <li className="c14 c19 c18 c21"><span className="c2">QUÀ XẾP HẠNG TUẦN</span></li>
              <li className="c14 c19 c18 c21"><span className="c2">QUÀ THÀNH TÍCH CÁ NHÂN</span></li>
            </ul>
            <table className="c11">
              <tbody>
                <tr className="c15">
                  <td className="c16" colSpan={2} rowSpan={1}>
                    <p className="c14 c19"><span className="c4">QUÀ XẾP HẠNG MÙA</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">#1</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Khủng Long Huyền Thoại dùng trong 30 ngày</span></p>
                    <p className="c6"><span className="c2">23 skin của 23 loại súng Free Fire dùng trong 30 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 1%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Khủng Long Huyền Thoại dùng trong 30 ngày</span></p>
                    <p className="c6"><span className="c2">Súng AK Kim Kỳ Lân dùng trong 30 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 5%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Sói Chiến Thần dùng trong 30 ngày</span></p>
                    <p className="c6"><span className="c2">Súng AK Kim Kỳ Lân dùng trong 30 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 10%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Sói Chiến Thần dùng trong 30 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 25%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Phù Thủy Hoàng Gia dùng trong 30 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 50%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">10 Vé Quay Vàng</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="c11">
              <tbody>
                <tr className="c15">
                  <td className="c16" colSpan={2} rowSpan={1}>
                    <p className="c14 c19"><span className="c4">QUÀ XẾP HẠNG TUẦN 1</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">#1</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Khủng Long Huyền Thoại dùng trong 7 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 1%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Vé Quay Kim Cương</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 5%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Thức Ăn Trợ Thủ</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 10%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Bản Đồ Kho Báu</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 25%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1000 Vàng</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="c11">
              <tbody>
                <tr className="c15">
                  <td className="c16" colSpan={2} rowSpan={1}>
                    <p className="c14 c19"><span className="c4">QUÀ XẾP HẠNG TUẦN 2</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">#1</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Khủng Long Huyền Thoại dùng trong 7 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 1%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Vé Quay Kim Cương</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 5%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Thức Ăn Trợ Thủ</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 10%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Bản Đồ Kho Báu</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 25%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1000 Vàng</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="c11">
              <tbody>
                <tr className="c15">
                  <td className="c16" colSpan={2} rowSpan={1}>
                    <p className="c14 c19"><span className="c4">QUÀ XẾP HẠNG TUẦN 3</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">#1</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Bộ Khủng Long Huyền Thoại dùng trong 7 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 1%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Vé Quay Kim Cương</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 5%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Thức Ăn Trợ Thủ</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 10%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Bản Đồ Kho Báu</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 25%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1000 Vàng</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="c11">
              <tbody>
                <tr className="c15">
                  <td className="c16" colSpan={2} rowSpan={1}>
                    <p className="c14 c19"><span className="c4">QUÀ XẾP HẠNG TUẦN 4</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 10</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">SCAR Huyết Nguyệt dùng trong 7 ngày</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 1%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Vé Quay Kim Cương</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 5%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Thức Ăn Trợ Thủ</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 10%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Bản Đồ Kho Báu</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Top 25%</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1000 Vàng</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="c11">
              <tbody>
                <tr className="c15">
                  <td className="c16" colSpan={2} rowSpan={1}>
                    <p className="c14 c19"><span className="c4">QUÀ CẤP ĐỘ CÁ NHÂN</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">3000 Điểm</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Thẻ Cấp Độ 6 Nhân Vật</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">2000 Điểm</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Hộp Mảnh Nhân Vật Alok</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1000 Điểm</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Thức Ăn Trợ Thủ</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">100 Điểm</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Drone Thính</span></p>
                  </td>
                </tr>
                <tr className="c0">
                  <td className="c12" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">1 Điểm</span></p>
                  </td>
                  <td className="c3" colSpan={1} rowSpan={1}>
                    <p className="c6"><span className="c2">Vé Quay Vàng</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="c22 c19"><span className="c2">*Các danh hiệu xếp hạng dưới sẽ không bao gồm những người đã được danh hiệu xếp hạng trên</span></p>
            <p className="c22 c19"><span className="c2">*Người chơi ở các danh hiệu hạng trên sẽ không nhận quà của các hạng dưới</span></p>
            <p className="c22 c19"><span className="c2">*Quà Mùa sẽ được trao 7 ngày sau khi kết thúc mùa giải. Quà Tuần sẽ được trao 3 ngày sau khi kết thúc tuần.</span></p>
            <p className="c19 c22"><span className="c2">*Cách tính danh hiệu vui lòng xem ở mục Hướng Dẫn</span></p>
            <p className="c22 c19"><span className="c2">*Danh sách skin súng dành cho #1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
            <table className="c11">
              <tbody>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">AK Kim Kỳ Lân 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">M4A1 Rừng Xanh 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">FAMAS Thần Khí Thiên Lôi 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">M249 Ai Cập 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">GROZA Giáng Sinh 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">SKS Tử Thần 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">M14 Báo Săn Mồi 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">SCAR Thủy Thần 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">M60 Bí Ngô 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">XM8 Ma Mị 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">AN94 Nghệ Sĩ Tử Thần 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">UMP Quái Thú 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">MP5 Thanh Lịch 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">MP40 Sấm Sét 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">P90 Viking 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">VSS Hàm Cá Mập 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">M1014 Đôi Mắt Tử Thần 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">SPAS12 Samurai Rồng 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">AWM Thợ săn 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">Kar98k Hàn Băng 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">M79 Đặc Nhiệm 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">Gậy Bắp Ngô 30 Ngày</span></p>
                  </td>
                </tr>
                <tr className="c1">
                  <td className="c13" colSpan={1} rowSpan={1}>
                    <p className="c14"><span className="c9">Kiếm Huyết Quỷ 30 Ngày</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Scrollbar>
        </div>
        <div className="text-center">
          <a href="#" className="btn" onClick={event => {
            event.preventDefault()
            showModalGuide()
            closeModal()
          }}>Hướng dẫn</a>
        </div>
      </div>
      <a onClick={event => selfClose(event)} className="close" data-dismiss="modal" aria-label="Close">
        ×
      </a>
    </Modal>
  )
}
export default ModalPrize