import React, { useState } from 'react'
import Modal from 'react-modal'
import Scrollbar from 'react-scrollbars-custom'

Modal.setAppElement('#root');

const ModalGuide = ({ modalStatus, closeModal, showModalPrize }) => {

  const afterOpenModal = () => {
  }

  const selfClose = (event) => {
    event.preventDefault()
    closeModal()
  }

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="swal2-show modal-select"
    >
      <div className="modal-description">
          <h2>Hướng dẫn</h2>
          <div className="guide-content">
            <Scrollbar 
              style={ {height: 200} }
              noScrollX={true}>
              <div>
                <ol className="c8 lst-kix_uh7ydg2cqxru-0 start" start={1}>
                  <li className="c10 c18"><span className="c4">CÁCH THAM GIA ĐUA TOP CHIẾN ĐỊA</span></li>
                </ol>
                <p className="c10"><span className="c2">Bước 1: Đăng nhập vào trang và chọn miền chiến địa: Bắc/ Trung/ Nam</span></p>
                <p className="c10"><span className="c2">Bước 2: Chọn tỉnh/ Thành phố chiến địa muốn tham gia và ấn “THAM GIA”</span></p>
                <p className="c10"><span className="c2">Bước 3: Tham gia kiếm điểm trong game để xếp hạng</span></p>
                <ol className="c8 lst-kix_uh7ydg2cqxru-0" start={2}>
                  <li className="c10 c18"><span className="c4">CÁCH XẾP HẠNG ĐUA TOP CHIẾN ĐỊA</span></li>
                </ol>
                <ul className="c8 lst-kix_79aiwbbdyspa-0 start">
                  <li className="c10 c18"><span className="c2">Kiếm điểm bằng cách chơi game</span></li>
                </ul>
                <ul className="c8 lst-kix_2zh1xgdbxcdk-0 start">
                  <li className="c7"><span className="c2">Đạt Top 1 nhận 15 Điểm/ lần</span></li>
                  <li className="c7"><span className="c2">Đạt Top 2 nhận 10 Điểm/ lần</span></li>
                  <li className="c7"><span className="c2">Đạt Top 3 nhận 5 Điểm/ lần</span></li>
                  <li className="c7"><span className="c2">Diệt mạng nhận 1 Điểm/ mạng</span></li>
                </ul>
                <ul className="c8 lst-kix_5tak58wyzwuo-0 start">
                  <li className="c10 c18"><span className="c2">Tổng Điểm của những người chơi đăng ký cùng 1 tỉnh/ Thành phố sẽ được so sánh và xếp hạng với nhau theo từng tuần hoặc cả mùa giải</span></li>
                  <li className="c10 c18"><span className="c2">Bảng Xếp Hạng của từng Chiến Địa sẽ được cập nhật vào 0:00 mỗi ngày</span></li>
                  <li className="c10 c18"><span className="c2">Dựa trên thành tích cá nhân mà người chơi sẽ nhận Danh hiệu xếp hạng tương ứng</span></li>
                  <li className="c10 c18"><span className="c2">Danh Hiệu dựa trên số điểm cá nhân và số lượng tổng người chơi toàn Chiến địa: #1, #2, #3, #4, #5, Top 1%, Top 5%, Top 10%, Top 10%, Top 25%, Top 50%</span></li>
                </ul>
                <ol className="c8 lst-kix_uh7ydg2cqxru-0" start={3}>
                  <li className="c10 c18"><span className="c4">CÁCH NHẬN QUÀ ĐUA TOP CHIẾN ĐỊA</span></li>
                </ol>
                <ul className="c8 lst-kix_rflvdiqo3m1i-0 start">
                  <li className="c10 c18"><span className="c2">Người chơi có thể nhận quà Xếp Hạng Cả Mùa, Xếp Hạng Tuần, Quà Cấp Độ Cá Nhân.</span></li>
                  <li className="c10 c18"><span className="c2">Danh sách chi tiết quà dành cho từng loại Danh hiệu Xếp Hạng vui lòng xem tại mục Phần Thưởng</span></li>
                  <li className="c10 c18">Bộ Khủng Long Huyền Thoại và Súng AK Kim Kỳ Lân sẽ trở thành những vật phẩm độc quyền chỉ có tại Đua Top Chiến Địa dành cho những người đứng đầu mỗi mùa.</li>
                </ul>
                <p className="c14 c19">
                </p>
              </div>
            </Scrollbar>
          </div>
        <div className="text-center">
          <a href="#" className="btn" onClick={event => {
            event.preventDefault()
            showModalPrize()
            closeModal()
          }}>Phần thưởng</a>
          <br/>
        </div>
      </div>
      <a onClick={event => selfClose(event)} className="close" data-dismiss="modal" aria-label="Close">
        ×
      </a>
    </Modal>
  )
}
export default ModalGuide