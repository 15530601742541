import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import config from 'config/common'
import queryString from 'query-string'
import request from 'lib/request'

import {
  getCurrentUser,
  showModalGuide,
  showModalPrize,
  closeModalPrize,
  closeModalGuide
} from 'authentication/actions'

import Header from './Header'

import ModalGuide from 'components/ModalGuide'
import ModalPrize from 'components/ModalPrize'

const PageLayout = ({
  currentUser,
  location,
  children,
  getCurrentUser,
  showModalGuide,
  showModalPrize,
  closeModalPrize,
  closeModalGuide
}) => {
  
  useEffect(() => {
    getCurrentUser()

    $(window).resize(function() {
      let e = $(window).width();
      e >= 1920 ? $("html").css("font-size", "10px") : e >= 1200 ? $("html").css("font-size", 10 * e / 1920 + "px") : e >= 1100 ? $("html").css("font-size", "10px") : $("html").css("font-size", 10 * e / 1300 + "px")
    })

    $(window).trigger('resize')

    const parsed = queryString.parse(location.search)
    const utm_source = parsed.utm_source
    const garena_token = parsed.garena_token
    let clientLogged = localStorage.getItem('garena_token')

    if(garena_token && garena_token != clientLogged && garena_token.length > 80) {
      request(`login/callback?access_token=${garena_token}&source_type=ingame`).then(function(response) {
        if(response.status == 'successful') {
          localStorage.setItem('garena_token', garena_token)
          window.location.reload()
        } else {
          lib.showError(response.error_code)
        }
      })
    }
  }, [])

  return (
    <>
      <Header currentUser={currentUser} />
      <section id="main-body" className={`page-${location.pathname != '/' ? location.pathname.replace('/', '') : ''}`}>
        <>
          {children}
        </>
      </section>

      {currentUser.modalGuideStatus && (          
        <ModalGuide
          modalStatus={currentUser.modalGuideStatus}
          closeModal={closeModalGuide}
          showModalPrize={showModalPrize}
        />
      )}

      {currentUser.modalPrizeStatus && (          
        <ModalPrize
          modalStatus={currentUser.modalPrizeStatus}
          closeModal={closeModalPrize}
          showModalGuide={showModalGuide}
        />
      )}
    </>
  )
}

const mapDispatchToProps = {
  getCurrentUser,
  showModalGuide,
  showModalPrize,
  closeModalPrize,
  closeModalGuide
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout))