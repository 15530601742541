import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const GET_CONFIG         = 'GET_CONFIG'
export const GET_CONFIG_ERROR   = 'GET_CONFIG_ERROR'
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS'
export const IS_GETTING_CONFIG  = 'IS_GETTING_CONFIG'

export const GET_RANK         = 'GET_RANK'
export const GET_RANK_ERROR   = 'GET_RANK_ERROR'
export const GET_RANK_SUCCESS = 'GET_RANK_SUCCESS'
export const IS_GETTING_RANK  = 'IS_GETTING_RANK'

export const JOIN         = 'JOIN'
export const JOIN_ERROR   = 'JOIN_ERROR'
export const JOIN_SUCCESS = 'JOIN_SUCCESS'
export const IS_JOINING   = 'IS_JOINING'

export const GET_STAT         = 'GET_STAT'
export const GET_STAT_ERROR   = 'GET_STAT_ERROR'
export const GET_STAT_SUCCESS = 'GET_STAT_SUCCESS'
export const IS_GETTING_STAT  = 'IS_GETTING_STAT'

export const GET_DETAIL         = 'GET_DETAIL'
export const GET_DETAIL_ERROR   = 'GET_DETAIL_ERROR'
export const GET_DETAIL_SUCCESS = 'GET_DETAIL_SUCCESS'
export const IS_GETTING_DETAIL  = 'IS_GETTING_DETAIL'

export const EXCHANGE         = 'EXCHANGE'
export const EXCHANGE_ERROR   = 'EXCHANGE_ERROR'
export const EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS'
export const IS_EXCHANGING    = 'IS_EXCHANGING'

export const SHOW_MODAL_GUIDE = 'SHOW_MODAL_GUIDE'
export const CLOSE_MODAL_GUIDE = 'CLOSE_MODAL_GUIDE'

export const SHOW_MODAL_PRIZE = 'SHOW_MODAL_PRIZE'
export const CLOSE_MODAL_PRIZE = 'CLOSE_MODAL_PRIZE'


export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response))
        const weekInfo = getState().currentUser.userSeasonInfo.current_week
        if(weekInfo) {
          dispatch(getRank(weekInfo.season_number, weekInfo.week, weekInfo.province))
        }
      } else {
        dispatch(getCurrentUserError(response))
      }
      dispatch(getConfig())
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}
//get config
export const isGettingConfig = () => {
  return {
    type: IS_GETTING_CONFIG
  }
}

export const getConfig = () => {
  return (dispatch, getState) => {
    dispatch(isGettingConfig());
    request('api/user/config').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getConfigSuccess(response));
      } else {
        dispatch(getConfigError(response))
      }
    })
  }
}

export const getConfigSuccess = (response) => {
  return {
    type: GET_CONFIG_SUCCESS,
    payload: response.payload
  }
}

export const getConfigError = (response) => {
  return {
    type: GET_CONFIG_ERROR,
    payload: response.payload
  }
}
//get rank
export const isGettingRank = () => {
  return {
    type: IS_GETTING_RANK
  }
}

export const getRank = (seasonNumber, week, provinceId) => {
  return (dispatch, getState) => {
    dispatch(isGettingRank());
    request('api/user/ranking', 'POST', {
      body: JSON.stringify({
        season_number: parseInt(seasonNumber),
        week: parseInt(week),
        province_id: parseInt(provinceId)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getRankSuccess(response));
      } else {
        dispatch(getRankError(response))
      }
    })
  }
}

export const getRankSuccess = (response) => {
  return {
    type: GET_RANK_SUCCESS,
    payload: response.payload
  }
}

export const getRankError = (response) => {
  return {
    type: GET_RANK_ERROR,
    payload: response.payload
  }
}

//join
export const isJoining = () => {
  return {
    type: IS_JOINING
  }
}

export const join = (provinceId) => {
  return (dispatch, getState) => {
    dispatch(isJoining())
    request('api/user/join_new_season', 'POST', {
      body: JSON.stringify({
        province_id: parseInt(provinceId)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(joinSuccess(response));
      } else {
        Swal.fire({
          title: 'Thông báo',
          'html': '<p>' + lib.showError(response.error_code) + '</p>',
          confirmButtonText: 'Xác nhận',
          showCancelButton: false,
          showCloseButton: true,
          customClass: {
            popup: 'popup-small'
          },
        })
        dispatch(joinError(response))
      }
    })
  }
}

export const joinSuccess = (response) => {
  return {
    type: JOIN_SUCCESS,
    payload: response.payload
  }
}

export const joinError = (response) => {
  return {
    type: JOIN_ERROR,
    payload: response.payload
  }
}
//get stat
export const isGettingStat = () => {
  return {
    type: IS_GETTING_STAT
  }
}

export const getStat = (seasonNumber) => {
  return (dispatch, getState) => {
    dispatch(isGettingStat());
    request('api/user/my_stat', 'POST', {
      body: JSON.stringify({
        season_number: parseInt(seasonNumber)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(getStatSuccess(response));
      } else {
        dispatch(getStatError(response))
      }
    })
  }
}

export const getStatSuccess = (response) => {
  return {
    type: GET_STAT_SUCCESS,
    payload: response.payload
  }
}

export const getStatError = (response) => {
  return {
    type: GET_STAT_ERROR,
    payload: response.payload
  }
}

//get detail
export const isGettingDetail = () => {
  return {
    type: IS_GETTING_STAT
  }
}

export const getDetail = () => {
  return (dispatch, getDetaile) => {
    dispatch(isGettingDetail());
    request('api/user/current_season_detail').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getDetailSuccess(response));
      } else {
        dispatch(getDetailError(response))
      }
    })
  }
}

export const getDetailSuccess = (response) => {
  return {
    type: GET_DETAIL_SUCCESS,
    payload: response.payload
  }
}

export const getDetailError = (response) => {
  return {
    type: GET_DETAIL_ERROR,
    payload: response.payload
  }
}
//exchange
export const isExchanging = (id) => {
  return {
    type: IS_EXCHANGING,
    payload: id
  }
}

export const exchange = (reward) => {
  return (dispatch, getState) => {
    dispatch(isExchanging(reward.id))
    request('api/user/exchange', 'POST', {
      body: JSON.stringify({
        reward_id: parseInt(reward.id)
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        dispatch(exchangeSuccess(response));
        Swal.fire({
          title: 'Chúc mừng',
          'html': '<p>Bạn đã nhận được <span class="highlight">' + reward.item_name + '</span></p>',
          confirmButtonText: 'Xác nhận',
          showCancelButton: false,
          showCloseButton: true,
          customClass: {
            popup: 'popup-small'
          },
        })
      } else {
        Swal.fire({
          title: 'Thông báo',
          'html': '<p>' + lib.showError(response.error_code) + '</p>',
          confirmButtonText: 'Xác nhận',
          showCancelButton: false,
          showCloseButton: true,
          customClass: {
            popup: 'popup-small'
          },
        })
        dispatch(exchangeError(response))
      }
    })
  }
}

export const exchangeSuccess = (response) => {
  return {
    type: EXCHANGE_SUCCESS,
    payload: response.payload
  }
}

export const exchangeError = (response) => {
  return {
    type: EXCHANGE_ERROR,
    payload: response.payload
  }
}

//Modal

export const showModalGuide = () => {
  return {
    type: SHOW_MODAL_GUIDE
  }
}
export const closeModalGuide = () => {
  return {
    type: CLOSE_MODAL_GUIDE
  }
}
export const showModalPrize = () => {
  return {
    type: SHOW_MODAL_PRIZE
  }
}
export const closeModalPrize = () => {
  return {
    type: CLOSE_MODAL_PRIZE
  }
}